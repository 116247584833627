/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { useContext } from '@nuxtjs/composition-api';
import { sharedRef } from '@vue-storefront/core';
import { useCart, useWishlist as useWishlistComposable, Product, useUser } from '@gemini-vsf/composables';
import { WishlistItem } from '@gemini-vsf/api-client';
import { getProductDetailsCommand } from '~/composables/useWishlist/getProductDetailCommand';
import { useAddToCart } from '~/helpers/cart/addToCart';

const useWishlist = (productList) => {
  const { addItem: addProductToCart, error: cartError } = useCart();
  const { isAuthenticated } = useUser();
  const { showCartError } = useAddToCart();
  const { wishlist, load: loadWishlistComposable, removeItem, addItem, hasItemsInWishlist, updateItem } = useWishlistComposable();
  const context = useContext();

  const itemsInWishlist = sharedRef<Record<string, boolean>>({}, 'useWishlist-itemsInWishlist');

  const filterProductSkus = (products?) => {
    const productsToFilter = products || productList.value;

    return productsToFilter
      .filter((product) => product && !product.isMoreItem)
      .reduce((accumulator, product) => {
        accumulator.push(product.sku);

        if (product?.variants) {
          product.variants.forEach((item) => {
            accumulator.push(item.product?.sku);
          });
        }

        return accumulator;
      }, []);
  };

  const pushItems = (items) => {
    itemsInWishlist.value = { ...itemsInWishlist.value, ...items };
  };

  const loadItemsFromList = async (initialProductList) => {
    // todo check if we can use some sort of cache here
    pushItems(await hasItemsInWishlist({ items: filterProductSkus(initialProductList) }));
  };

  const toggleWishlist = async (product: Product) => {
    await (itemsInWishlist.value[product.sku] ? removeItem({ product }) : addItem({ product, quantity: 1 }));
    pushItems(await hasItemsInWishlist({ items: filterProductSkus([product]) }));
  };

  // const { app, $vsf } = useContext;

  const removeItemFromWishlist = async ({ product }) => {
    if (product.wishlist_uid) {
      await removeItem({ product: { ...product.product, variantGrn: product.item_grn } });
      pushItems(await hasItemsInWishlist({ items: filterProductSkus([product.product]) }));
    } else {
      await removeItem({ product });
      pushItems(await hasItemsInWishlist({ items: filterProductSkus([product]) }));
    }
  };

  const addItemToCart = async (wishlistItem: WishlistItem, doRemove = true) => {
    const productGrn = wishlistItem.item_grn;
    const productId = wishlistItem.product?.uid.split('::')?.[1];
    const variant = wishlistItem.product.variants.find((item) => item.product.uid === productGrn);
    const product = await getProductDetailsCommand.execute(context, {
      filter: {
        // @ts-ignore
        uid: productId,
      },
      // @ts-ignore
      configurations: variant.option_uids as string[],
    });
    await addProductToCart({ product: product as any, quantity: wishlistItem.qty });
    if (cartError.value.addItem) {
      showCartError(cartError.value.addItem);
      return;
    }

    // @ts-ignore
    if (doRemove) {
      await removeItemFromWishlist({ product: { ...wishlistItem.product, variantGrn: productGrn } });
    }
  };

  const addItemToWishlist = async (productAndQuantity) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    await addItem(productAndQuantity);
  };

  const loadWishlist = async (payload) => {
    await loadWishlistComposable({
      ...payload,
      customQuery: isAuthenticated.value
        ? {
            customerWishlist: 'customerWishlistCustom',
          }
        : {
            wishlist: 'wishlistCustom',
          },
    });
  };

  return {
    itemsInWishlist,
    loadItemsFromList,
    toggleWishlist,
    removeItemFromWishlist,
    addItemToWishlist,
    addItemToCart,
    wishlist,
    loadWishlist,
    updateItem,
    cartError,
  };
};

export default useWishlist;
