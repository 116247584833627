import { useUiNotification } from '~/composables';
import { useI18n } from '~/helpers/hooks/usei18n';
import { ref } from '@nuxtjs/composition-api';

export interface FormData {
  [key: string]: string;
}

const SUCCESS_MESSAGE = 'Your request was submitted successfully! We will get back to you as soon as possible.';
const ERROR_MESSAGE = 'An error occurred while sending your request, please reload page or contact us directly at info@sognidicristallo.it';
const FORMSPREE_BASE_URL = 'https://formspree.io/f/';

const useFormspree = (formspreeFormId: string) => {
  const { send: sendNotification } = useUiNotification();
  const translate = useI18n();
  const formUrl = ref(`${FORMSPREE_BASE_URL}${formspreeFormId}`);
  const loading = ref<boolean>(false);
  const hasSubmitError = ref<boolean>(false);

  const notifyError = (onFailure = null) => {
    // Send a notification with an error message
    sendNotification({
      id: Symbol('quote_form_error'),
      message: translate.t(ERROR_MESSAGE) as string,
      type: 'danger',
      persist: false,
      title: translate.t('An error occurred') as string,
      icon: '',
    });
    if (onFailure) onFailure();
  };

  const sendDataToFormspree = async (formData: FormData, onSuccess?: () => void, onFailure?: () => void) => {
    // If the form ID is not defined, send an error notification and return
    if (!formUrl.value) notifyError(onFailure);

    loading.value = true;
    const response = await fetch(formUrl.value, {
      method: 'POST',
      body: JSON.stringify(formData),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    loading.value = false;

    if (response.ok) {
      sendNotification({
        id: Symbol('quote_form_success'),
        message: `${translate.t(SUCCESS_MESSAGE)}`,
        type: 'success',
        icon: '',
        persist: false,
        title: `${translate.t('Request sent')}`,
      });
      if (onSuccess) onSuccess();
      hasSubmitError.value = false;
      return;
    }
    sendNotification({
      id: Symbol('quote_form_error'),
      message: `${translate.t(ERROR_MESSAGE)}`,
      type: 'danger',
      icon: '',
      persist: false,
      title: `${translate.t('An error occurred')}`,
    });
    if (onFailure) onFailure();
    hasSubmitError.value = true;
  };

  return {
    sendDataToFormspree,
    hasSubmitError,
    loading,
  };
};

export default useFormspree;
