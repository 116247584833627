export const redirects = [
  { from: '/eu-fr/editorial/contract', to: '/eu-fr/contract', type: 301 },
  { from: '/eu-es/editorial/contract', to: '/eu-es/contract', type: 301 },
  { from: '/eu-de/editorial/contract', to: '/eu-de/contract', type: 301 },
  { from: '/eu-en/editorial/contract', to: '/eu-en/contract', type: 301 },
  { from: '/it-it/editorial/contract', to: '/it-it/contract', type: 301 },
  // same for about
  { from: '/eu-fr/editorial/about', to: '/eu-fr/about', type: 301 },
  { from: '/eu-es/editorial/about', to: '/eu-es/sobre-nosotros', type: 301 },
  { from: '/eu-de/editorial/about', to: '/eu-de/uber-uns', type: 301 },
  { from: '/eu-en/editorial/about', to: '/eu-en/about', type: 301 },
  { from: '/it-it/editorial/about', to: '/it-it/about', type: 301 },
  // same for lavorazione
  { from: '/eu-fr/editorial/lavorazione', to: '/eu-fr/traitement', type: 301 },
  { from: '/eu-es/editorial/lavorazione', to: '/eu-es/procesamiento', type: 301 },
  { from: '/eu-de/editorial/lavorazione', to: '/eu-de/handwerkskunst', type: 301 },
  { from: '/eu-en/editorial/lavorazione', to: '/eu-en/craftsmanship', type: 301 },
  { from: '/it-it/editorial/lavorazione', to: '/it-it/lavorazione', type: 301 },
  // same for contact
  { from: '/eu-fr/editorial/contact', to: '/eu-fr/contact', type: 301 },
  { from: '/eu-es/editorial/contact', to: '/eu-es/contacto', type: 301 },
  { from: '/eu-de/editorial/contact', to: '/eu-de/kontakt', type: 301 },
  { from: '/eu-en/editorial/contact', to: '/eu-en/contact', type: 301 },
  { from: '/it-it/editorial/contact', to: '/it-it/contact', type: 301 },
];
