import { useUrlResolver as urlResolver } from '@gemini-vsf/composables';
import { useRoute } from '@nuxtjs/composition-api';

export const useUrlResolver = () => {
  const route = useRoute();
  const {
    params: { path },
  } = route.value;

  const { search, result, loading } = urlResolver(`router:${path}`);

  return {
    path,
    search: async () => {
      await search({ url: path });
    },
    result,
    loading,
  };
};
