/* eslint-disable unicorn/consistent-function-scoping */
const dataManipulation = () => {
  const geminiGrnToUid = (grnString: string) => {
    if (!grnString.includes('grn:') || !grnString.includes('::')) {
      throw new Error('Provided string is not a valid GRN.');
    }

    const grnArray = grnString.split('::');

    if (grnArray.length !== 2 || grnArray[1].length === 0) {
      throw new Error('Could not find uuid from provided GRN.');
    }

    return grnArray[1];
  };

  const arrayIsValidAndHasItems = (array: []): boolean => array && Array.isArray(array) && array.length > 0;

  const objectIsNotEmpty = (object: Record<string, unknown>): boolean =>
    object && Object.keys(object).length === 0 && Object.getPrototypeOf(object) === Object.prototype;

  const vueObjectToNormalObject = (object: Record<string, unknown>): Record<string, unknown> => ({ ...object });
  const vueArrayToNormalArray = (array: []): [] => [...array];

  const daysFromNowTo = (daysToAdd: number) => {
    try {
      const ms = Date.now() + daysToAdd * 24 * 60 * 60 * 1000;
      const date = new Date(ms);
      return {
        day: date.getDate().toLocaleString(),
        month: date.toLocaleString('en-US', { month: 'long' }),
        year: date.getFullYear().toLocaleString().replaceAll(',', ''),
      };
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const stringIsUUID = (string: string) => /^[\da-f]{8}(?:\b-[\da-f]{4}){3}\b-[\da-f]{12}$/gi.test(string);

  const isValidUrl = (urlString: string) => {
    const urlPattern = new RegExp(
      '^(https?:\\/\\/)?' + // validate protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // validate fragment locator
    return !!urlPattern.test(urlString);
  };

  const beautifyAttributeName = (string: string) => {
    if (!string.includes('sdc') || !string.includes('_')) return null;
    const attributeNameWithoutPrefix = string.replace('sdc_', '').replace('_attribute', '');
    const splitAttributeName = attributeNameWithoutPrefix.split('_');
    return splitAttributeName.map((s) => `${s.charAt(0).toUpperCase()}${s.slice(1)}`).join(' ');
  };

  return {
    geminiGrnToUid,
    arrayIsValidAndHasItems,
    objectIsNotEmpty,
    vueObjectToNormalObject,
    vueArrayToNormalArray,
    daysFromNowTo,
    stringIsUUID,
    beautifyAttributeName,
    isValidUrl,
  };
};

export default dataManipulation;
