import { onMounted, ref, Ref, watch, useRoute } from '@nuxtjs/composition-api';

const useWindow = () => {
  const realWindowHeight: Ref<number> = ref(0);
  const windowWidth: Ref<number> = ref(0);
  const windowHeight: Ref<number> = ref(0);
  const realHeaderHeight: Ref<number> = ref(0);
  const realWindowHeightExtended: Ref<number> = ref(0);
  const isDesktop: Ref<boolean> = ref(true);
  const isTablet: Ref<boolean> = ref(false);
  const isMobile: Ref<boolean> = ref(false);
  const isDesktopOrTablet: Ref<boolean> = ref(true);
  const isMobileOrTablet: Ref<boolean> = ref(false);
  const ready: Ref<boolean> = ref(false);
  const stickyHeader: Ref<boolean> = ref(false);

  /**
   * Updates the height of the window based on the height of the header element.
   * It first tries to find the header element by querying the DOM for the first element with the class `app-header`.
   * If it can't find the header element, it logs a warning and returns.
   * If it does find the header element, it calculates the height of the header and its distance from the top of the window.
   * It then sets the values of several reactive variables based on these calculations.
   * Finally, it sets the value of `windowWidth` to the current width of the window.
   */
  const updateWindowHeight = () => {
    let header: HTMLElement;
    try {
      header = document.querySelectorAll('.app-header')[0] as HTMLElement;
    } catch {
      console.warn('Could not find header height');
      return;
    }
    const headerHeight: number = header.offsetHeight;
    const headerFromTop: number = header.getBoundingClientRect().top;
    windowHeight.value = window.innerHeight;
    realWindowHeight.value = window.innerHeight - headerHeight - headerFromTop;
    realWindowHeightExtended.value = window.innerHeight - headerFromTop;
    realHeaderHeight.value = headerHeight + headerFromTop;
    windowWidth.value = window.innerWidth;
  };

  const updateDeviceInfo = () => {
    isDesktop.value = window.innerWidth > 1023;
    isTablet.value = window.innerWidth > 767 && window.innerWidth < 1024;
    isMobile.value = window.innerWidth < 768;
    isDesktopOrTablet.value = isDesktop.value || isTablet.value;
    isMobileOrTablet.value = isMobile.value || isTablet.value;
  };

  const handleBodyClass = (action: string, className: string) => {
    const element = document.body;
    if (action === 'addClass') {
      element.classList.add(className);
    } else {
      element.classList.remove(className);
    }
  };

  const handleHtmlClass = (action: string, className: string) => {
    const element = document.querySelector('html');
    if (action === 'addClass') {
      element.classList.add(className);
    } else {
      element.classList.remove(className);
    }
  };

  const setStickyHeader = () => {
    stickyHeader.value = window.scrollY >= 30;
  };

  onMounted(() => {
    updateWindowHeight();
    updateDeviceInfo();
    setStickyHeader();
    window.addEventListener('scroll', () => {
      // large scrollY to "await" header's transition and avoid wrong updates during quick scrolls
      if (window.scrollY <= Number.parseInt('1000', 10)) {
        updateWindowHeight();
      }
      setStickyHeader();
    });
    window.addEventListener('resize', () => {
      updateDeviceInfo();
    });
    ready.value = true;
  });

  const route = useRoute();
  watch(
    () => route.value.fullPath,
    (newValue, oldValue) => {
      if (newValue !== oldValue) {
        handleHtmlClass('removeClass', 'no-scroll');
      }
    }
  );

  return {
    ready,
    isMobile,
    isTablet,
    isDesktop,
    stickyHeader,
    realHeaderHeight,
    isMobileOrTablet,
    realWindowHeight,
    isDesktopOrTablet,
    realWindowHeightExtended,
    windowWidth,
    updateWindowHeight,
    updateDeviceInfo,
    handleHtmlClass,
    handleBodyClass,
    windowHeight,
  };
};

export default useWindow;
