import { computed, reactive, watch, useRoute } from '@nuxtjs/composition-api';
import { useWindow } from '~/composables';

const state = reactive({
  // Sidebar
  openedSidebar: '',
  sidebarData: '{}',

  // Modal
  openedModal: '',
  modalData: '{}',

  // Search
  isSearchModalOpen: false,
});

const useUiState = () => {
  const { handleHtmlClass } = useWindow();

  // Sidebar
  const openedSidebar = computed(() => state.openedSidebar);
  const sidebarData = computed(() => JSON.parse(state.sidebarData));
  const openSidebar = (name: string, data = {}) => {
    handleHtmlClass('addClass', 'no-scroll');
    if (state.openedSidebar) {
      state.openedSidebar = '';
    }
    state.sidebarData = JSON.stringify(data);
    state.openedSidebar = name;

    const iubendaButton = document.querySelectorAll<HTMLElement>('.iubenda-tp-btn');
    if (iubendaButton?.length > 0) {
      iubendaButton[0].style.zIndex = '4';
    }
  };
  const closeSidebar = (name = '') => {
    if (state.openedSidebar === name || !name) {
      handleHtmlClass('removeClass', 'no-scroll');
      state.openedSidebar = '';
    }
  };
  const toggleSidebar = (name: string, data = {}) => {
    if (state.openedSidebar === name) {
      closeSidebar();
    } else {
      openSidebar(name, data);
    }
  };
  // eslint-disable-next-line unicorn/consistent-function-scoping
  const updateSidebarData = (name: string, data = {}) => {
    if (state.openedSidebar === name) {
      state.sidebarData = JSON.stringify(data);
    }
  };

  // Modal
  const openedModal = computed(() => state.openedModal);
  const modalData = computed(() => JSON.parse(state.modalData));
  const openModal = (name: string, data = {}) => {
    handleHtmlClass('addClass', 'no-scroll');
    if (state.openedModal) {
      state.openedModal = '';
    }
    state.modalData = JSON.stringify(data);
    state.openedModal = name;
  };
  const closeModal = (name = '') => {
    if (state.openedModal === name || !name) {
      handleHtmlClass('removeClass', 'no-scroll');
      state.openedModal = '';
    }
  };
  const toggleModal = (name: string, data = {}) => {
    if (state.openedModal === name) {
      closeModal();
    } else {
      openModal(name, data);
    }
  };

  const getModalData = (): string => {
    return state.modalData;
  };

  // Search
  const isSearchModalOpen = computed(() => state.isSearchModalOpen);
  const toggleSearchModal = () => {
    closeSidebar();
    state.isSearchModalOpen = !state.isSearchModalOpen;
  };

  const route = useRoute();
  watch(
    () => route.value.fullPath,
    (newValue, oldValue) => {
      if (newValue !== oldValue) {
        closeSidebar();
        closeModal();
      }
    }
  );

  return {
    // Sidebar
    openedSidebar,
    sidebarData,
    openSidebar,
    closeSidebar,
    toggleSidebar,
    updateSidebarData,

    // Modal
    openedModal,
    modalData,
    openModal,
    toggleModal,
    closeModal,
    getModalData,

    // Search
    isSearchModalOpen,
    toggleSearchModal,
  };
};

export default useUiState;
