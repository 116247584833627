import { useContext } from '@nuxtjs/composition-api';
import { GetProductSearchParams } from '@gemini-vsf/api-client';
import { Scalars } from '@gemini-vsf/api-client/lib/types/GraphQL';

type UseContextReturn = ReturnType<typeof useContext>;
type ProductSearchParams = GetProductSearchParams & { configurations?: Scalars['ID'] };

export const getProductDetailsCommand = {
  execute: async (context: UseContextReturn, searchParams: ProductSearchParams) => {
    const { data } = await context.app.$vsf.$gemini.api.productDetail(searchParams);

    return data?.productDetail ?? null;
  },
};
