/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMeta as useMetaCompositionApi } from '@nuxtjs/composition-api';
import { useI18n } from '~/helpers/hooks/usei18n';

const useMeta = () => {
  const trans = useI18n();
  const defaultRobots = 'index, follow';
  // eslint-disable-next-line max-params
  const generatePageMeta = (title: string, description?: string, keywords?: string, robots = defaultRobots) => {
    //@ts-ignore
    useMetaCompositionApi(() => ({
      title: title || trans.t('Loading..'),
      meta: [
        {
          hid: 'title',
          name: 'title',
          content: title || trans.t('Loading..'),
        },
        {
          hid: 'og:title',
          name: 'og:title',
          content: title || trans.t('Loading..'),
        },
        {
          hid: 'description',
          name: 'description',
          content: description || title || '',
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: description || title || '',
        },
        {
          hid: 'robots',
          name: 'robots',
          content: robots,
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: keywords || '',
        },
      ],
    }));
  };

  return {
    generatePageMeta,
  };
};

export default useMeta;
