/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ref } from '@nuxtjs/composition-api';
import useGeminiApi from '~/composables/useGeminiApi/useGeminiApi';

const useBlog = () => {
  const { getPayloadData } = useGeminiApi();

  const blogPageId = '669f9f4cfbc2d97b21fa705c';

  const blogBlocks = ref();
  const loadBlogBlocks = async () => {
    try {
      blogBlocks.value = await getPayloadData('pages', blogPageId);
    } catch (error) {
      console.error('Unable to fetch home content.', error);
    }
  };

  return {
    loadBlogBlocks,
    blogBlocks,
  };
};

export default useBlog;
