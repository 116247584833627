const imageSizes = {
  productCard: {
    width: 216,
    height: 268,
  },
  productCardHorizontal: {
    width: 140,
    height: 200,
  },
  checkout: {
    imageWidth: 100,
    imageHeight: 100,
  },
  productGallery: {
    thumbWidth: 160,
    thumbHeight: 160,
    imageWidth: 1080,
    imageHeight: 1340,
  },
  cart: {
    imageWidth: 170,
    imageHeight: 170,
  },
};

export function useImage() {
  /**
   * Extract image path from Gemini URL.
   *
   * @param fullImageUrl {string}
   *
   * @return {string}
   */
  const getGeminiImage = (fullImageUrl: string) => {
    // NOTE: originally used to extract the cache path from the image url
    // since it is not used anymore we can safely return the full url
    return fullImageUrl;
  };

  return {
    getGeminiImage,
    imageSizes,
  };
}

export default useImage;
export * from './useImage';
