import { computed, useRoute } from '@nuxtjs/composition-api';
const IUBENDA_BASE_URL = 'https://www.iubenda.com/privacy-policy';
const usePrivacy = () => {
  const route = useRoute();
  const privacyPolicyLink = computed(() => {
    const lang = route?.value?.fullPath?.split('/')?.[1]?.split('-')?.[1] || 'en';
    switch (lang) {
      case 'it': {
        return `${IUBENDA_BASE_URL}/40334898`;
      }
      case 'en': {
        return `${IUBENDA_BASE_URL}/97514437`;
      }
      case 'de': {
        return `${IUBENDA_BASE_URL}/12624426`;
      }
      case 'fr': {
        return `${IUBENDA_BASE_URL}/53965455`;
      }
      case 'es': {
        return `${IUBENDA_BASE_URL}/89231971`;
      }
      // default to english if something goes wrong
      default: {
        return `${IUBENDA_BASE_URL}/97514437`;
      }
    }
  });

  return {
    privacyPolicyLink,
  };
};

export default usePrivacy;
