import { useContext, computed, ref } from '@nuxtjs/composition-api';
import { sharedRef } from '@vue-storefront/core';
import { useMarkets as useMarketsComposable } from '@gemini-vsf/composables';
import handleMarketsAndLocales, { CustomLocale } from '~/helpers/geminiConfig/handleMarketsAndLocales';
import cookieNames from '~/enums/cookieNameEnum';

const useMarkets = () => {
  const { markets, load: loadMarkets } = useMarketsComposable();
  const { getLocaleFromRoute } = handleMarketsAndLocales();
  const {
    app: {
      $i2c: countryIsoToName,
      $config: { markets: marketsAndLocalesConfig, ueMarkets: ueMarketsConfig, farEastMarket },
      $cookies: { get: getCookie },
    },
    route,
  } = useContext();
  const marketCookie = ref(getCookie(cookieNames.marketCookieName) || '');

  const currentMarketCountries = computed(
    () => marketsAndLocalesConfig.find((market) => market.code === route?.value?.fullPath?.split('/')?.[1])?.countries || []
  );

  const isUeMarket = computed(
    () => !marketCookie.value || !ueMarketsConfig || ueMarketsConfig.length === 0 || ueMarketsConfig.includes(marketCookie.value)
  );

  const isFarEastMarket = computed(() => farEastMarket && marketCookie.value && farEastMarket === marketCookie.value);

  const availableCountries = sharedRef<Record<string, boolean>>({}, 'useMarkets-availableCountries');
  const availableCountriesWithFullName = sharedRef<Record<string, boolean>>({}, 'useMarkets-availableCountriesWithFullName');
  const marketAndCurrencyLabel = computed(() => {
    const currency = getCookie(cookieNames.currencyCookieName) || 'EUR';
    let market: string | null;
    try {
      [market] = getLocaleFromRoute(route.value).split('-');
    } catch {
      market = 'IT';
    }
    return `${market.toUpperCase()}/${currency}`;
  });

  const languageMap = new Map(Object.entries({ it: 'Italian', en: 'English', de: 'German', fr: 'French', es: 'Spanish' }));

  const findMarketForCountryIso = (countryIso: string, locale: string): string => {
    const market = marketsAndLocalesConfig.find((m: CustomLocale) => m.countries.includes(countryIso.toUpperCase()));
    if (!locale) return market.code || 'it-it';
    return `${market.code.split('-')[0]}-${locale}`;
  };

  const getAllAvailableCountries = async () => {
    if (markets.value.length === 0) await loadMarkets();
    if (!markets.value || markets.value.length === 0) {
      availableCountries.value = [];
      return;
    }

    availableCountries.value = markets.value.reduce((accumulator, market) => [...accumulator, ...market.countries], []);
    availableCountries.value.sort((a, b) => a.localeCompare(b));
  };

  const getAllAvailableCountriesWithFullName = async () => {
    if (!availableCountries.value || !Array.isArray(availableCountries.value)) await getAllAvailableCountries();
    if (availableCountries.value.length === 0) {
      availableCountriesWithFullName.value = [];
      return;
    }

    availableCountriesWithFullName.value = availableCountries.value.map((isoCode: string) => ({
      value: isoCode,
      label: countryIsoToName(isoCode),
    }));
  };

  const getAvailableLanguagesForCountry = async (countryIso: string) => {
    if (markets.value.length === 0) await loadMarkets();
    if (!markets.value || markets.value.length === 0) {
      return [];
    }

    return marketsAndLocalesConfig.find((market: CustomLocale) => market.countries.includes(countryIso.toUpperCase())).availableLocales || markets[0];
  };

  return {
    isUeMarket,
    isFarEastMarket,
    load: loadMarkets,
    markets,
    getAllAvailableCountries,
    availableCountries,
    getAllAvailableCountriesWithFullName,
    availableCountriesWithFullName,
    getAvailableLanguagesForCountry,
    languageMap,
    findMarketForCountryIso,
    marketAndCurrencyLabel,
    currentMarketCountries,
  };
};

export default useMarkets;
