/* eslint-disable no-secrets/no-secrets */
import { CartItem, useCart as useCartComposable, useUser } from '@gemini-vsf/composables';
import { computed } from '@nuxtjs/composition-api';

const useCart = () => {
  const {
    load: loadCart,
    cart,
    addItem: addItemComposable,
    addNoteOnCart: addNoteOnCartComposable,
    applyCoupon: applyCouponComposable,
    applyGiftCard: applyGiftCardComposable,
    removeCoupon: removeCouponComposable,
    removeGiftCard: removeGiftCardComposable,
    removeItem: removeItemComposable,
    updateItemQty: updateItemQtyComposable,
    isInCart,
    loading,
    error,
  } = useCartComposable();

  const { isAuthenticated, load: loadUser } = useUser();

  const cartTotalItems = computed(() => (cart?.value && cart.value?.total_quantity) || null);
  const cartItems = computed(() => (cart?.value && cart.value?.items.filter(Boolean)) || []);

  const load = async () => {
    await loadUser();
    await loadCart({
      customQuery: isAuthenticated.value
        ? {
            customerCart: 'customerCartCustom',
          }
        : {
            cart: 'cartCustom',
          },
    });
  };

  const addItem = async (item: CartItem) => {
    await addItemComposable({
      ...item,
      customQuery: {
        addConfigurableProductsToCart: 'addConfigurableProductsToCartCustom',
      },
    });
  };

  const addNoteOnCart = async (note: string) => {
    await addNoteOnCartComposable({
      note: note,
      // customQuery: { setNotesToCart: 'setNotesToCartCustom' },
    });
  };

  const applyCoupon = async (couponCode: string) => {
    await applyCouponComposable({
      couponCode,
      // customQuery: {
      //   applyCouponToCart: 'applyCouponToCartCustom',
      // },
    });
  };

  const applyGiftCard = async (giftCardCode: string) => {
    await applyGiftCardComposable({
      giftCardCode,
      // customQuery: {
      //   applyGiftCardToCart: 'applyGiftCardToCartCustom',
      // },
    });
  };

  const removeCoupon = async (couponCode: string) => {
    await removeCouponComposable({
      couponCode,
      // customQuery: {
      //   removeCouponFromCart: 'removeCouponFromCart',
      // },
    });
  };

  const removeGiftCard = async (giftCardCode: string) => {
    await removeGiftCardComposable({
      giftCardCode,
      // customQuery: { removeGiftCardFromCart: 'removeGiftCardFromCartCustom' },
    });
  };

  const removeItem = async (product: CartItem) => {
    await removeItemComposable({
      product,
      customQuery: {
        removeItemFromCart: 'removeItemFromCartCustom',
      },
    });
  };

  const updateItemQty = async (product: CartItem, quantity: number) => {
    await updateItemQtyComposable({
      product,
      quantity,
      customQuery: {
        updateCartItems: 'updateCartItemsCustom',
      },
    });
  };

  return {
    ...useCartComposable(),
    load,
    cart,
    addItem,
    addNoteOnCart,
    applyCoupon,
    applyGiftCard,
    removeCoupon,
    removeGiftCard,
    removeItem,
    updateItemQty,
    isInCart,
    cartTotalItems,
    cartItems,
    loading,
    error,
  };
};

export default useCart;
