export const magentoStoreviewCodesMapping = [
  { magentoStoreViewCode: 'it', geminiCode: 'it-it' },
  { magentoStoreViewCode: 'es', geminiCode: 'eu-es' },
  { magentoStoreViewCode: 'world', geminiCode: 'row-en' },
  { magentoStoreViewCode: 'fr-fr', geminiCode: 'eu-fr' },
  { magentoStoreViewCode: 'es-es', geminiCode: 'eu-es' },
  { magentoStoreViewCode: 'de-de', geminiCode: 'eu-de' },
  { magentoStoreViewCode: 'en', geminiCode: 'eu-en' },
  { magentoStoreViewCode: 'eu', geminiCode: 'eu-en' },
  { magentoStoreViewCode: 'fr', geminiCode: 'eu-fr' },
  { magentoStoreViewCode: 'de-ch', geminiCode: 'ch-de' },
  { magentoStoreViewCode: 'asia', geminiCode: 'fe-en' },
  { magentoStoreViewCode: 'it-ch', geminiCode: 'ch-it' },
];
