interface Market {
  uid: string;
  grn: string;
  name: string;
  description: string;
  countries: string[];
}

export interface CustomLocale {
  code: string;
  file: string;
  defaultCurrency: string;
  marketGrn: string;
  localeValue: string;
  availableLocales: string[];
  countries: string[];
}

const handleDefaultDescription = (marketId: string) => (process.env[marketId] ? process.env[marketId].split('|') : [null, null, null]);

const handleMarketsAndLocales = () => {
  return {
    generateLocales: (markets: Market[]) => {
      const localesArray = [];
      markets.forEach((market) => {
        const [marketCode, localesString, defaultCurrency] =
          market.description && market.description.includes('|') ? market.description.split('|') : handleDefaultDescription(market.uid);
        if (localesString) {
          const locales = localesString.split(',');
          locales.forEach((locale) => {
            localesArray.push({
              code: `${marketCode}-${locale}`,
              file: `${locale}.js`,
              defaultCurrency,
              marketGrn: market.grn,
              localeValue: locale,
              availableLocales: locales,
              countries: market.countries,
            });
          });
        }
      });
      return localesArray;
    },
    findMarketForLocaleCode: (markets: CustomLocale[], localeCode: string): CustomLocale => markets.find((m) => m.code === localeCode) ?? markets[0],
    getLocaleFromRoute: (route: { fullPath: string }): string => {
      const localeArray = route.fullPath ? route.fullPath.split('/') : null;
      let localeCodeFromRoute = '';
      if (localeArray && localeArray.length > 0) {
        [, localeCodeFromRoute] = localeArray;
        const cleanLocaleWithoutParameters = localeCodeFromRoute.split('?');
        if (cleanLocaleWithoutParameters.length > 0) {
          [localeCodeFromRoute] = cleanLocaleWithoutParameters;
        }
        return localeCodeFromRoute;
      } else if (!localeArray) {
        console.error('handleMarketsAndLocales.ts ~ could not get locale array');
      }
      return localeCodeFromRoute;
    },
  };
};

export default handleMarketsAndLocales;
