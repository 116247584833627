/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */ // ToDo: handle types
import { ref } from '@nuxtjs/composition-api';
import type { Ref } from '@nuxtjs/composition-api';
import axios, { AxiosResponse } from 'axios';
interface Ipify extends AxiosResponse {
  data: {
    ip: string;
  };
}
/* Response is available in these languages, not in use now since there's only
IT and EN in this project. To enable it just pass the locale in params
using language as key. */

const useGeoLocation = () => {
  const location: Ref<string> = ref(null);
  const loading: Ref<boolean> = ref(false);
  const error: Ref<string> = ref(null);
  const ip: Ref<string> = ref(null);
  const getClientIp = async () => {
    loading.value = true;
    ip.value = null;
    error.value = null;
    try {
      const ipResponse: Ipify = await axios({
        method: 'get',
        url: 'https://api.ipify.org',
        params: {
          format: 'json',
        },
      });
      console.debug(`getClientIp composable response: ${ipResponse.data.ip}`);
      ip.value = ipResponse.data.ip;
      loading.value = false;
    } catch (error_) {
      /* Error would be lost when function is called in getLocation,
      saving value in error ref only works if this is called in FE */
      // error.value = err;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      throw new Error(error_);
    }
  };
  const getLocation = async () => {
    loading.value = true;
    location.value = null;
    error.value = null;
    try {
      await getClientIp();
      const response = await axios.get(`/api/get-geo-location`, {
        params: {
          ip: ip.value,
        },
        headers: {
          Accept: 'text/plain, json/application',
        },
      });
      console.debug('getLocation composable response:', response);
      if (response.data?.error) {
        error.value = response.data.error;
      } else {
        location.value = response.data;
      }
      loading.value = false;
    } catch (error_) {
      error.value = error_;
      loading.value = false;
    }
  };
  return {
    location,
    loading,
    error,
    ip,
    getLocation,
    getClientIp,
  };
};
export default useGeoLocation;
