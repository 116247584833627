import { useContext } from '@nuxtjs/composition-api';
const attributeCodeTranslationsMap = new Map([
  ['sdc_colore_vetro', { it: 'Colore Vetro', en: 'Glass Color', fr: 'Couleur du Verre', de: 'Glasfarbe', es: 'Color de Cristal' }],
  ['sdc_numero_luci', { it: 'Numero Luci', en: 'Number of Lights', fr: 'Nombre de Lumières', de: 'Anzahl der Lichter', es: 'Número de Luces' }],
  ['sdc_color_mount', { it: 'Colore Montatura', en: 'Frame Color', fr: 'Couleur du Cadre', de: 'Rahmenfarbe', es: 'Color del Marco' }],
  ['paralume', { it: 'Paralume', en: 'Lampshade', fr: 'Ombre', de: 'Lampenschirm', es: 'Pantalla de Lámpara' }],
  [
    'sdc_dimensioni_oggettistica',
    { it: 'Dimensioni Oggettistica', en: 'Object Size', fr: 'Cotations Objets', de: 'Bemaßungen von Objekten', es: 'Cotas Objetos' },
  ],
  ['set_bicchieri', { it: 'Set Bicchieri', en: 'Glasses Set', fr: 'Ensemble de Lunettes', de: 'Brillenset', es: 'Juego de Gafas' }],
  [
    'composizione_balls',
    { it: 'Composizione Palle', en: 'Ball Composition', fr: 'Composition du Ballon', de: 'Ballzusammensetzung', es: 'Composición de la Pelota' },
  ],
  ['sdc_lunghezza_catena', { it: 'Lunghezza Catena', en: 'Chain Length', fr: 'Longueur de Chaîne', de: 'Kettenlänge', es: 'Longitud de la Cadena' }],
  ['sdc_size', { it: 'Dimensioni', en: 'Size', fr: 'Dimensions', de: 'Maße', es: 'Dimensiones' }],
]);

const useAttributeCodeTranslation = () => {
  const {
    app: {
      i18n: {
        localeProperties: { localeValue },
      },
    },
  } = useContext();
  const translateAttributeCode = (attributeCode: string): string => attributeCodeTranslationsMap.get(attributeCode)?.[localeValue] || null;

  return { translateAttributeCode };
};

export default useAttributeCodeTranslation;
