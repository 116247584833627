const formatCurrency = (value: number | string, locale: string, options: Intl.NumberFormatOptions): string => {
  if (typeof value === 'string') {
    // eslint-disable-next-line no-param-reassign
    value = Number(value);
  }
  // eslint-disable-next-line no-param-reassign

  if (value % 1 === 0) {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      ...options,
    }).format(value);
  }

  return new Intl.NumberFormat(locale, { style: 'currency', minimumFractionDigits: 2, ...options }).format(value);
};

export default formatCurrency;
