/* eslint-disable no-secrets/no-secrets */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable max-lines-per-function */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useContext, computed, useRoute } from '@nuxtjs/composition-api';
import { useCart, cartGetters, productGetters, useUser, userGetters } from '@gemini-vsf/composables';
import dataManipulation from '~/helpers/dataManipulation';

interface GtmPagePush {
  event: string;
  content_name: string;
  content_url: string;
  item_id?: string | number;
}

const useGtm = () => {
  const {
    app: {
      $gtm: googleTagManager,
      i18n: { locales },
      $cookies,
    },
  } = useContext();
  // @ts-ignore
  const currency = $cookies.get('vsf-currency');
  const fullPath = useRoute();
  const currentLocaleCode = computed(() => fullPath.value.fullPath.split('/')[1]);
  // @ts-ignore
  const currentCurrency = computed(() => locales.find((l) => l.code === currentLocaleCode.value)?.defaultCurrency);
  const isClientAndHasCurrency = computed(() => !!process.client && currentCurrency.value);
  const { cart } = useCart();
  const { isAuthenticated, user } = useUser();
  const { arrayIsValidAndHasItems, vueArrayToNormalArray } = dataManipulation();
  const checkoutGtmPush = (step: number, description: string, event: string) => {
    try {
      const productsInCart =
        cartGetters
          .getItems(cart.value)
          .filter((p) => p.product && p.quantity && p.prices)
          .map((value, index) => ({
            item_id: productGetters.getProductMerchantSku(value.product).split('_')[0],
            item_name: productGetters.getName(value.product),
            price: value.prices.row_total.value ?? value.prices.row_regular_total.value,
            quantity: value.quantity,
            index,
            item_variant: productGetters.getProductMerchantSku(value.product),
          })) ?? [];
      //@ts-ignore
      if (isClientAndHasCurrency.value && arrayIsValidAndHasItems(productsInCart) && cart.value) {
        googleTagManager.push({
          event,
          ecommerce: {
            currency,
            value: cart.value.prices?.grand_total?.value || '',
            items: {
              ...productsInCart,
            },
            checkout: {
              actionField: {
                step,
                description,
                action: 'checkout',
              },
            },
          },
        });
      }
    } catch {
      console.warn('Could not send checkout GTM event.');
    }
  };

  const cartGtmPush = () => {
    try {
      const productsInCart =
        cartGetters
          .getItems(cart.value)
          .filter((p) => p.product && p.quantity && p.prices)
          .map((value, index) => ({
            item_id: productGetters.getProductMerchantSku(value.product).split('_')[0],
            item_name: productGetters.getName(value.product),
            price: value.prices.row_total.value ?? value.prices.row_regular_total.value,
            quantity: value.quantity,
            index,
            item_variant: productGetters.getProductMerchantSku(value.product),
          })) ?? [];
      // @ts-ignore
      if (isClientAndHasCurrency.value && arrayIsValidAndHasItems(productsInCart) && cart.value) {
        googleTagManager.push({
          ecommerce: {
            items: { ...productsInCart },
            transaction_id: cart.value.id,
            value: cart.value.prices.grand_total.value,
            currency,
            affiliation: 'sognidicristallo.com',
            PageType: 'cart-page',
          },
          event: 'view_cart',
        });
      }
    } catch {
      console.warn('Could not send cart GTM event.');
    }
  };

  const categoryGtmPush = (products, categoryName) => {
    try {
      const productsList = products.filter(Boolean).map((p, index) => ({
        item_id: productGetters.getProductMerchantSku(p).split('_')[0] || p.merchant_sku,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        item_name: productGetters.getName(p) || p.name,
        // eslint-disable-next-line unicorn/prefer-logical-operator-over-ternary, @typescript-eslint/no-unsafe-argument
        price:
          p.price_range?.minimum_price?.final_price?.value === p.price_range?.maximum_price?.final_price?.value
            ? p.price_range?.maximum_price?.final_price?.value
            : p.price_range?.minimum_price?.final_price?.value,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        item_category: categoryName,
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        position: index + 1,
      }));

      if (isClientAndHasCurrency.value && arrayIsValidAndHasItems(productsList)) {
        googleTagManager.push({
          ecommerce: {
            items: {
              ...productsList,
            },
          },
          PageType: 'product-list',
          event: 'view_item_list',
        });
      }
    } catch {
      console.warn('Could not send category GTM event.');
    }
  };

  const customerGtmPush = () => {
    try {
      if (isClientAndHasCurrency.value) {
        if (isAuthenticated.value) {
          googleTagManager.push({
            customerLoggedIn: 1,
            // @ts-ignore
            firstname: userGetters.getFirstName(user.value),
            // @ts-ignore
            lastname: userGetters.getLastName(user.value),
            // @ts-ignore
            email: userGetters.getEmailAddress(user.value),
          });
          return;
        }
        googleTagManager.push({
          customerLoggedIn: 0,
          customerId: 0,
          customerGroupId: '1',
          customerGroupCode: 'GENERAL',
        });
      }
    } catch {
      console.warn('Could not send customer GTM event.');
    }
  };

  const pageGtmPush = (pageTitle: string, productMerchantSku = null) => {
    try {
      if (fullPath.value.fullPath && pageTitle) {
        const pagePushContent: GtmPagePush = {
          event: 'PageView',
          content_name: pageTitle,
          content_url: `https://www.sognidicristallo.com${fullPath.value.fullPath}`,
        };
        if (productMerchantSku) pagePushContent.item_id = productMerchantSku;
        googleTagManager.push(pagePushContent);
      }
    } catch {
      console.warn('Could not send page GTM event.');
    }
  };

  const productGtmPush = (product, brand = 'Sogni Di Cristallo') => {
    const selectedVariant = product?.configurable_product_options_selection?.variant;
    try {
      if (isClientAndHasCurrency.value && product) {
        const productDataGtm = {
          item_id: selectedVariant?.merchant_sku || productGetters.getProductMerchantSku(product).split('_')[0],
          item_name: productGetters.getName(product),
          price: selectedVariant?.price_range.minimum_price.final_price.value || productGetters.getFinalPrice(product),
          category: productGetters.getCategoryNameProductPage(product, ...product.categories) || [],
          // @ts-ignore
          list_sku_variants: vueArrayToNormalArray(productGetters.getListVariantSku(product)),
          item_brand: brand,
        };
        googleTagManager.push({
          ecommerce: {
            currency,
            value: productDataGtm.price,
            items: [
              {
                ...productDataGtm,
                categoryId: product.categories.find((c) => c.name === productDataGtm.category).uid,
              },
            ],
            PageType: 'product-page',
          },
          event: 'view_item',
        });
      }
    } catch {
      console.warn('Could not send product GTM event.');
    }
  };

  const purchaseGtmPush = (order) => {
    try {
      if (isClientAndHasCurrency.value && order && order.transactionId) {
        googleTagManager.push({
          event: 'purchase',
          currency,
          ecommerce: {
            ...order,
          },
        });
      }
    } catch {
      console.warn('Could not send purchase GTM event.');
    }
  };

  const addToWishlistGtmPush = (product, variant) => {
    try {
      if (!!product.product && !!isClientAndHasCurrency.value) {
        const productWishlistGtmData = {
          item_id: variant?.merchant_sku || product.product.merchant_sku,
          item_name: product.product.name,
          price: variant?.price_range.minimum_price.final_price.value || productGetters.getFinalPrice(product.product),
          item_brand: 'Sogni di Cristallo',
        };
        googleTagManager.push({
          ecommerce: {
            currency,
            value: productWishlistGtmData.price,
            items: [
              {
                ...productWishlistGtmData,
              },
            ],
            quantity: product.quantity,
          },
          event: 'add_to_wishlist',
        });
      }
    } catch {
      console.warn('Could not send wishlist GTM event.');
    }
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const actionCartGtmPush = (product, quantity: number, event: string, gtmIndex: number, productPrice) => {
    try {
      if (isClientAndHasCurrency.value && product && quantity) {
        googleTagManager.push({
          event: `${event}`,
          ecommerce: {
            items: [
              product?.vsf_typename === 'ConfigurableProduct'
                ? {
                    item_id: product.configurable_product_options_selection?.variant.merchant_sku || productGetters.getProductMerchantSku(product),
                    item_name: productGetters.getName(product) || '',
                    price:
                      product.configurable_product_options_selection?.variant?.price_range.minimum_price.final_price.value ||
                      productGetters.getFinalPrice(product),
                    item_brand: 'Sogni di Cristallo',
                    index: gtmIndex || '',
                    quantity,
                  }
                : {
                    item_id:
                      product.configurable_product_options_selection?.variant.merchant_sku ??
                      productGetters.getProductMerchantSku(product).split('_')[0],
                    item_name: productGetters.getName(product) || '',
                    item_brand: 'Sogni di Cristallo',
                    price: productGetters.getFinalPrice(product),
                    index: gtmIndex ?? '',
                    item_variant:
                      product.configurable_product_options_selection?.variant.merchant_sku || productGetters.getProductMerchantSku(product),
                    quantity,
                  },
            ],
            value:
              event === 'remove_from_cart'
                ? productPrice.row_total.value
                : event === 'add_to_cart'
                ? product.configurable_product_options_selection?.variant.price_range.minimum_price.final_price.value
                : productGetters.getFinalPrice(product),
            currency,
          },
        });
      }
    } catch {
      console.warn('Could not send cart GTM event.');
    }
  };

  const searchGtmPush = (term: string) => {
    try {
      if (isClientAndHasCurrency.value && term) {
        googleTagManager.push({
          event: 'search',
          search_term: term,
        });
      }
    } catch {
      console.warn('Could not send search GTM event.');
    }
  };

  const formActionGtmPush = (formAction: string, formSubmitErrors: boolean) => {
    try {
      if (formAction) {
        googleTagManager.push({
          event: formAction,
          success: !formSubmitErrors,
        });
      }
    } catch {
      console.warn('Could not send form submit GTM event.');
    }
  };

  return {
    addToWishlistGtmPush,
    checkoutGtmPush,
    cartGtmPush,
    categoryGtmPush,
    customerGtmPush,
    pageGtmPush,
    productGtmPush,
    purchaseGtmPush,
    actionCartGtmPush,
    searchGtmPush,
    formActionGtmPush,
  };
};

export default useGtm;
