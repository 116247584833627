import { CmsBlock } from '@gemini-vsf/api-client';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const getIdentifier = (item: any): string => item.identifier || '';
export const getContent = (item: any): string => item.content || '';
export const getMetaTitle = (item: any): string => item.meta_title || '';
export const getMetaDescription = (item: any): string => item.meta_description || '';
export const getContentHeading = (item: any): string => item.content_heading || '';
export const getTitle = (item: any): string => item.title || '';

type BlockContent = {
  [key: string]: any;
};

type ParsedBlocksContent = Record<string, BlockContent>;

// cms
export const getCmsBlockContent = (items: readonly CmsBlock[]): any =>
  items.reduce((prev: ParsedBlocksContent, item: CmsBlock) => {
    try {
      prev[item.identifier] = JSON.parse(item.content);
    } catch {
      console.warn(`An error occurred while parsing block ${item.identifier}`);
      prev[item.identifier] = {};
    }

    return prev;
  }, {});

const contentGetters = {
  getIdentifier,
  getContent,
  getMetaTitle,
  getMetaDescription,
  getContentHeading,
  getTitle,
  getCmsBlockContent,
};

export default contentGetters;
