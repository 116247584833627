/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ref, useContext } from '@nuxtjs/composition-api';
import axios from 'axios';
import { isClient } from '~/helpers/check-environment';

const NUXT_CMS_ENDPOINT = 'gemini/getCmsPage';

const useCms = () => {
  const cmsData = ref(null) as Record<string, unknown>;
  const {
    app: {
      $config: { ssrMiddlewareUrl, middlewareUrl },
    },
  } = useContext();

  const getCmsPage = async (entityId: string) => {
    try {
      const { data } = await axios.get(`${isClient ? middlewareUrl : ssrMiddlewareUrl}${NUXT_CMS_ENDPOINT}`, {
        params: {
          entityId,
        },
      });
      cmsData.value = data;
    } catch (error) {
      console.error(error);
    }
  };

  return {
    cmsData,
    getCmsPage,
  };
};

export default useCms;
