module.exports = {
  it: 'it-IT',
  'it-IT': 'it',
  de: 'de-DE',
  'de-DE': 'de',
  fr: 'fr-FR',
  'fr-FR': 'fr',
  en: 'en-US',
  'en-US': 'en',
  es: 'es-ES',
  'es-ES': 'es',
};
